// Home.js
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import IntroBox from './IntroBox';
import FeaturesBox from './FeaturesBox';
import ConsultationCTA from './ConsultationCTA';
import Tools from './Tools';



AOS.init();

const Home = () => {
  return (
    <div>
      <IntroBox />
      <FeaturesBox />
      <Tools />
      <ConsultationCTA />
 
    </div>
  );
};

export default Home;
