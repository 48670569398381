import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter,faGithub, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../Styles/Footer.css'; // Ensure this path matches your file structure

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        <p>© {new Date().getFullYear()} The ZYT Group. All rights reserved.</p>
        <div className="social-links">
          <a href="https://x.com/thezytgroup?s=11&t=kk802i6kJRC-8P4wb4n_GQ" aria-label="Twitter">
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <a href="https://wa.me/message/2HKG7MYJ5F7WP1" aria-label="Facebook">
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
          <a href="https://x.com/thezytgroup?s=11&t=kk802i6kJRC-8P4wb4n_GQ" aria-label="Twitter">
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href="https://www.instagram.com/thezytgroup/" aria-label="Instagram">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
