import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/NavBar.css";

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to close the menu
  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleBookConsultation = () => {
    window.location.href = "https://calendly.com/thezytgroup/30min";
    closeMenu(); // Close the menu after redirection
  };

  return (
    <nav className="navbar">
      <div className="brand-name">The ZYT Group</div>
      <div className="menu-text" onClick={toggleMenu}>
        {menuOpen ? "CLOSE" : "EXPLORE"}
      </div>
      <div className="links-container">
        <ul className={`nav-links ${menuOpen ? "open" : ""}`}>
          <li onClick={closeMenu}>
            <Link to="/">Home</Link>
          </li>
          <li onClick={closeMenu}>
            <Link to="/services">Services</Link>
          </li>
          <li onClick={closeMenu}>
            <Link to="/about">About</Link>
          </li>
          <li onClick={closeMenu}>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <button onClick={handleBookConsultation}>
              Book Consultation
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
