import React from 'react';
import '../Styles/About.css'; // Make sure this path matches your file structure

function About() {
  return (
    <div className="about-container">
      <section className="hero-section">
        <h1>The ZYT Group</h1>
        <p>Innovating the future, one project at a time.</p>
      </section>
      
      <section className="history-section">
        <h2>Our Journey</h2>
        <p>Founded in the heart of innovation, The ZYT Group has been a beacon of creativity and technological advancement since its inception. With a relentless focus on excellence, we have empowered countless businesses to achieve their digital dreams.</p>
      </section>
      
      <section className="mission-section">
        <h2>Our Mission</h2>
        <p>Our mission is to transform the digital landscape by delivering exceptional design, cutting-edge technology, and strategic SEO solutions. We believe in creating value for our clients, driving growth, and fostering sustainable success.</p>
      </section>
      
      <section className="values-section">
        <h2>Our Values</h2>
        <ul>
          <li>Innovation at Every Step</li>
          <li>Integrity and Transparency</li>
          <li>Commitment to Excellence</li>
          <li>Empowering Client Success</li>
        </ul>
      </section>

      <section className="team-section">
        <h2>Meet Our Team</h2>
        <p>A passionate group of thinkers, creators, and leaders, our team is the driving force behind our success.</p>
        {/* Include more detailed team bios if desired */}
      </section>

      <section className="culture-section">
        <h2>Our Culture</h2>
        <p>At The ZYT Group, we cultivate a culture of continuous learning, collaboration, and diversity, encouraging creativity and innovation at every level.</p>
      </section>

      <section className="vision-section">
        <h2>Our Vision</h2>
        <p>Looking ahead, we aim to set new standards in digital excellence, becoming a global leader in driving digital transformation and growth.</p>
      </section>
    </div>
  );
}

export default About;
