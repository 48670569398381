import React from 'react';
import '../Styles/FeaturesBox.css'; // Ensure the path to your CSS file is correct

function FeaturesBox() {
  return (
    <div className="features-container">
      <h2>What We Offer</h2>
      <div className="features-grid">
        <div className="feature-item">
          <h3>Website Design</h3>
          <p>Create visually appealing and user-friendly designs.</p>
        </div>
        <div className="feature-item">
          <h3>Website Creation</h3>
          <p>Develop high-quality, responsive websites tailored to your needs.</p>
        </div>
        <div className="feature-item">
          <h3>Search Engine Optimization</h3>
          <p>Optimize your website for better visibility and ranking on search engines.</p>
        </div>
        <div className="feature-item">
          <h3>Graphic Design</h3>
          <p>Enhance your brand with stunning graphics and visual content.</p>
        </div>
      </div>
    </div>
  );
}

export default FeaturesBox;
