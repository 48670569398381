import React from 'react';
import '../Styles/ConsultationCTA.css'; // Ensure the path to your CSS file is correct

function ConsultationCTA() {
  return (
    <div className="consultation-cta-container">
      <h2>Ready to Supercharge Your Business?</h2>
      <p>Book a consultation with us to learn how we can help!</p>
      <a href="https://calendly.com/thezytgroup/30min" target="_blank" rel="noopener noreferrer">
        <button className="book-consultation-btn">Book Consultation</button>
      </a>
    </div>
  );
}

export default ConsultationCTA;
