import React from "react";
import "../Styles/IntroBox.css"; // Ensure this path is correct
import logoImage from "../Images/ZYTLogo1.png"; // Import your image here, if it's a local file

const IntroBox = () => {
  return (
    <div className="intro-box">
      <img src={logoImage} alt="ZYT Logo" className="intro-logo" />
    </div>
  );
};

export default IntroBox;
