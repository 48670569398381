import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import NavBar from './Components/NavBar';
import Home from './Components/Home';
import ContactForm from './Components/Contact';
import ServicesPage from './Components/ServicesPage';
import About from './Components/About';
import Footer from './Components/Footer';
import ConsultationForm from './Components/ConsultationForm';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/consultationform" element={<ConsultationForm />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;