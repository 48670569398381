import React from 'react';
import '../Styles/ServicesPage.css'; // Ensure this path is correct

function ServiceBox({ title, description }) {
  // For mobile, the isActive state toggle isn't needed
  return (
    <div className="service-box">
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
}

function ServicesPage() {
  return (
    <div className="services-container">
      <div className="services-header">
        <h1>Our Services</h1>
        <p className="hover-instruction">hover to discover</p>
      </div>
      <div className="services-grid">
        <ServiceBox title="DESIGN" description="We craft bespoke website designs tailored to meet the unique needs of your business, ensuring a memorable online presence." />
        <ServiceBox title="CREATE" description="From concept to launch, we develop responsive, user-friendly websites that reflect the essence of your brand and engage your visitors." />
        <ServiceBox title="OPTIMIZE" description="Our SEO strategies are designed to increase your website's visibility and drive more organic traffic, enhancing your online reach." />
        <ServiceBox title="REIMAGINE" description="Our creative graphic design solutions communicate your brand's message visually, from logos and branding to marketing materials." />
      </div>
    </div>
  );
}

export default ServicesPage;
