import React, { useRef, useCallback } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter as faXTwitter, // Assuming faXTwitter is the correct import alias you want to use
  faGithub,
  faInstagram,
  faLinkedinIn,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "../Styles/Contact.css";
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {
  const [state, handleSubmit] = useForm(process.env.REACT_APP_FORMSPREE_FORM_ID);
  const recaptchaRef = useRef(null);

  const executeRecaptcha = useCallback(async (action) => {
    if (recaptchaRef.current) {
      return await recaptchaRef.current.execute(action);
    }
    return null; // Handle case where recaptchaRef is not yet initialized
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();

    const token = await executeRecaptcha("submit");
    if (!token) {
      console.error("ReCAPTCHA token was not generated.");
      return; // Prevent form submission if no reCAPTCHA token is generated
    }

    const formData = new FormData(event.target);
    formData.append("g-recaptcha-response", token);

    handleSubmit(formData).then((response) => {
      if (response && response.ok) {
        console.log("Form submitted successfully.");
        // You can implement additional success logic here
      } else {
        console.error("Form submission failed with response:", response);
        // Implement failure logic or notification to the user here
      }
    }).catch((error) => {
      console.error("An error occurred during form submission:", error);
    });
  };

  if (state.succeeded) {
    return (
      <p className="submission-success">
        Thank you for contacting us. We will try to respond within 24-48 hours.
      </p>
    );
  }

  return (
    <div className="contact-container">
      <div className="contact-form">
        <h2>Contact Us</h2>
        <form onSubmit={onSubmit}>
          <label htmlFor="email">Email Address</label>
          <input id="email" type="email" name="email" required />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" required />
          <ValidationError prefix="Message" field="message" errors={state.errors} />
          
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            action="submit"
          />
          
          <button type="submit" disabled={state.submitting}>
            Submit
          </button>
        </form>
      </div>

      {/* Social links and contact details unchanged */}
      <div className="contact-details">
        <div className="follow-us">
          <h3>Follow Us</h3>
          <div className="social-links">
            {/* Correct FontAwesomeIcon usage with fixed icon names */}
            <a href="https://twitter.com/theZYTgroup" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a href="https://www.instagram.com/thezytgroup/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://github.com/thezytgroup" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a href="https://linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a href="https://wa.me/message/2HKG7MYJ5F7WP1" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
              <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp Us
            </a>
          </div>
        </div>

        <div className="talk-to-us">
          <h3>Talk to Us</h3>
          <p>157 Beach 5th Street, Far Rockaway, NY 11691</p>
          <p>Contact@thezytgroup.com</p>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
