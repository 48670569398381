import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 110px;
  margin-bottom: 50px;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 25px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #555;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #4caf50;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #4caf50;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
  resize: vertical;
  min-height: 120px;

  &:focus {
    outline: none;
    border-color: #4caf50;
  }
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 14px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.div`
  padding: 20px;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  color: #155724;
  text-align: center;
  margin-top: 30px;
`;

const ConsultationForm = () => {
  const [state, handleSubmit] = useForm(process.env.REACT_APP_CONSULTATION_FORMSPREE_FORM_ID);

  if (state.succeeded) {
    return (
      <Container>
        <SuccessMessage>
          <p>Thank you for submitting the questionnaire!</p>
        </SuccessMessage>
      </Container>
    );
  }

  return (
    <Container>
      <h1>Client Questionnaire</h1>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">Name:</Label>
          <Input type="text" id="name" name="name" required />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email:</Label>
          <Input type="email" id="email" name="email" required />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="phone">Phone:</Label>
          <Input type="tel" id="phone" name="phone" required />
          <ValidationError prefix="Phone" field="phone" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="company">Company:</Label>
          <Input type="text" id="company" name="company" required />
          <ValidationError prefix="Company" field="company" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="industry">Industry:</Label>
          <Select id="industry" name="industry" required>
            <option value="">Select an industry</option>
            <option value="Technology">Technology</option>
            <option value="Finance">Finance</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Retail">Retail</option>
            <option value="Other">Other</option>
          </Select>
          <ValidationError prefix="Industry" field="industry" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="website">Website:</Label>
          <Input type="url" id="website" name="website" />
          <ValidationError prefix="Website" field="website" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="projectType">Project Type:</Label>
          <Select id="projectType" name="projectType" required>
            <option value="">Select a project type</option>
            <option value="Web Development">Web Development</option>
            <option value="Mobile App Development">Mobile App Development</option>
            <option value="UI/UX Design">UI/UX Design</option>
            <option value="Branding">Branding</option>
            <option value="Other">Other</option>
          </Select>
          <ValidationError prefix="Project Type" field="projectType" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="budget">Budget:</Label>
          <Select id="budget" name="budget" required>
            <option value="">Select a budget range</option>
            <option value="Less than $5,000">Less than $5,000</option>
            <option value="$5,000 - $10,000">$5,000 - $10,000</option>
            <option value="$10,000 - $25,000">$10,000 - $25,000</option>
            <option value="$25,000 - $50,000">$25,000 - $50,000</option>
            <option value="More than $50,000">More than $50,000</option>
          </Select>
          <ValidationError prefix="Budget" field="budget" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="timeline">Project Timeline:</Label>
          <Select id="timeline" name="timeline" required>
            <option value="">Select a project timeline</option>
            <option value="1-3 months">1-3 months</option>
            <option value="3-6 months">3-6 months</option>
            <option value="6-12 months">6-12 months</option>
            <option value="More than 12 months">More than 12 months</option>
          </Select>
          <ValidationError prefix="Project Timeline" field="timeline" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="goals">Project Goals:</Label>
          <Textarea id="goals" name="goals" rows="4" required></Textarea>
          <ValidationError prefix="Project Goals" field="goals" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="targetAudience">Target Audience:</Label>
          <Textarea id="targetAudience" name="targetAudience" rows="4" required></Textarea>
          <ValidationError prefix="Target Audience" field="targetAudience" errors={state.errors} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="additionalInfo">Additional Information:</Label>
          <Textarea id="additionalInfo" name="additionalInfo" rows="4"></Textarea>
          <ValidationError prefix="Additional Information" field="additionalInfo" errors={state.errors} />
        </FormGroup>
        <Button type="submit" disabled={state.submitting}>
          {state.submitting ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </Container>
  );
};

export default ConsultationForm;